import React from 'react';
import primary from "../../Assets/Images/primaryManagers.jpeg";
import secondary from "../../Assets/Images/secondaryManagers.jpeg";
import axcapital from "../../Assets/Images/axcapital.jpeg"
import axholding from "../../Assets/Images/axholding.jpeg"
import training from "../../Assets/Images/training.jpg"
const Manager = ({type}) => {
    console.log(type);
    return (
        <div className="org-chart">
            {type === "secondaryManagers" ? (
                <img src={secondary} alt="Secondary Manager" />
            ) : type === "axcapitalOrgchart" ? (
                <img src={axcapital} />
            ) : type ===  "axholdingOrgchart" ? (
                <img src={axholding} />
            ) : type ===  "training" ? (
                <img src={training} />
            ) : (
                <img src={primary} alt="Primary Manager" />
            )}
        </div>
    );
};

export default Manager;
