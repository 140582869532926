import { Link } from "react-router-dom"

export default function Header(){
    return (
        <>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#">Dashboard Admin</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                    <div className="navbar-nav">
                        <Link to="/utils" className="nav-link active" aria-current="page">Utils</Link>
                        
                        {/* <a className="nav-link" href="#">Features</a> */}
                        {/* <a className="nav-link" href="#">Pricing</a> */}
                        {/* <a className="nav-link disabled" aria-disabled="true">Disabled</a> */}
                    </div>
                    </div>
                </div>
            </nav>
        </>
    )
}