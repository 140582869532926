import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Slides from './Components/Slides';
import Admin from './Components/Admin/Admin';
import Login from './Components/Admin/Login';
import EditUtils from './Components/Admin/EditUtils'

import { AuthProvider, useAuth } from './Components/Admin/AuthContext';
import './Assets/css/style.css';

import Newjoiners from '../src/Components/Templates/Newjoiners'

const PrivateRoute = ({ element: Component, ...rest }) => {
  const { token } = useAuth();
  return token ? <Component {...rest} /> : <Navigate to="/admin/login" />;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Slides id="1" />} />
            <Route path="/:id" element={<Slides />} />
            <Route path="/admin" element={<PrivateRoute element={Admin} />} />
            <Route path="/utils" element={<PrivateRoute element={EditUtils} />} />
            <Route path="/admin/login" element={<Login />} />
            <Route path="/newjoiners" element={<Newjoiners/>}/>
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
