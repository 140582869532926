import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import Header from './Sections/Header';

const Admin = () => {
  

  return (
    <div>
      <Header />
      
    </div>
  );
};

export default Admin;
