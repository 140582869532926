export default function Birthday({ data }){
    const json = JSON.parse(data)
    console.log(json)

    return(
        <>
            <div className="announcesingle">
                    <div className="container-fluid happy-banner">
                    {/* grid-custom */}
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="happe-text">🎉 Happy Birthday! 🎉</h1>
                            </div>
                        </div>
                        <div className="row"> 
                            <div className="col-md-6 offset-md-1">
                                <div className="h-100 d-flex align-items-center">
                                    <div className="context">
                                        {/* <p className="upper">Agent and Deal Type:</p> */}
                                        {/* <p className="sub-title deal-type">{json.DealType}</p> */}
                                        {/* <p className="upper">Developer, Project and Amount:</p> */}
                                        {/* <h3>{json.Developer} - {json.Project}</h3> */}
                                        {/* <p className="sub-title price">AED {Amount}</p> */}
                                        <p className="price">Congratulations</p>
                                        <h3>{json.name}</h3>
                                        <hr />
                                        <p className="sub-title mt-2">Your special day is here, and we hope it’s filled with joy, laughter, and memorable moments. Thank you for being a valued member of our team. Wishing you a fantastic year ahead!</p>
                                        <p className="sub-title mt-3" >Best wishes, <br /> AX CAPITAL</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="banner">
                                    <div className="img" 
                                        style={{
                                            backgroundImage: `url(${json.img})`
                                    }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}