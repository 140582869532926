import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const { setToken } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:4009/api/admin/login', { username, password });
      const token = response.data.token;
      setToken(token);
      localStorage.setItem('authToken', token); // Сохраняем токен в localStorage
      navigate('/admin'); // Перенаправляем пользователя на страницу admin после успешного логина
    } catch (error) {
      console.error('Ошибка входа:', error);
      alert('Wrong credentials');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>Имя пользователя:</label>
        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
      </div>
      <div>
        <label>Пароль:</label>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      </div>
      <button type="submit">Войти</button>
    </form>
  );
};

export default Login;
