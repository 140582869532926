import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Deal from './Templates/Deal';
import Manager from './Templates/Manager';
import Dashboard from './Templates/Dashboard';
import Reviews from './Templates/Reviews';
import Newjoiners from './Templates/Newjoiners';
import Nodeals from './Templates/Nodeals';
import Birthday from './Templates/Birthday'

const Slides = () => {
  const { id } = useParams();
  const [slide, setSlide] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalSlides, setTotalSlides] = useState(0);
  const [duration, setDuration] = useState(20000); // Default duration
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSlide = async (id) => {
      setLoading(true);
      try {
        const response = await fetch(`https://dash2.axc.ae/api/slide/${id}`);
        const data = await response.json();
        if (data && data.active) {
          setSlide(data);
          // data.duration ||
          setDuration(data.duration || 20000); // Use slide-specific duration if available
        } else {
          // Если слайд не активен или не найден, переходим к следующему
          goToNextSlide(parseInt(id, 10) + 1);
        }
        setLoading(false);
      } catch (error) {
        console.error('Ошибка:', error);
        setLoading(false);
      }
    };

    fetchSlide(id);
  }, [id]);

  useEffect(() => {
    const fetchTotalSlides = async () => {
      try {
        const response = await fetch('https://dash2.axc.ae/api/slides/count');
        const data = await response.json();
        setTotalSlides(data.count);
      } catch (error) {
        console.error('Ошибка при получении количества слайдов:', error);
      }
    };

    fetchTotalSlides();
  }, []);

  const goToNextSlide = (nextId) => {
    if (totalSlides > 0) {
      const nextSlideId = ((nextId - 1) % totalSlides) + 1;
      console.log(`Current ID: ${id}, Next ID: ${nextId}, Total Slides: ${totalSlides}, Calculated Next Slide ID: ${nextSlideId}`);
      navigate(`/${nextSlideId}`);
    }
  };
  

  useEffect(() => {
    const interval = setInterval(() => {
      goToNextSlide(parseInt(id, 10) + 1);
    }, duration); // Use dynamic duration

    return () => clearInterval(interval);
  }, [id, totalSlides, duration]);

  const renderSlide = (slide) => {
    switch (slide.type) {
      case 'deal':
        return <Deal data={slide.data} />;
      case 'secondaryManagers':
      case 'primaryManagers':
      case 'axcapitalOrgchart':
      case 'axholdingOrgchart':
      case 'training':
        return <Manager type={slide.type} />;
      case 'dashboard':
        return <Dashboard data={slide.data} />;
      case 'reviews':
        return <Reviews data={slide.data} />;
      case 'newjoiners':
        return <Newjoiners data={slide.data} />;
      case 'birthday':
          return <Birthday data={slide.data} />;
      case 'nodeals':
        return <Nodeals data={slide.data} />;
      default:
        return <div>Неизвестный тип слайда</div>;
    }
  };

  useEffect(() => {
    const updateSlideState = async () => {
      if (slide && [3, 5, 7, 9].includes(slide.order_num)) {
        try {
          await fetch('https://dash2.axc.ae/api/deactivate-slide', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: slide.id, active: 0 }) // Делаем слайд неактивным
          });
        } catch (error) {
          console.error('Ошибка при обновлении слайда:', error);
        }
      }
    };

    updateSlideState();
  }, [slide]);

  if (loading) {
    return <div>Загрузка...</div>;
  }

  if (!slide) {
    return <div className='text-bg-danger'>Not active slide</div>;
  }

  return (
    <div>
      {renderSlide(slide)}
    </div>
  );
};

export default Slides;
