import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, faEdit } from '@fortawesome/free-solid-svg-icons';
import Header from './Sections/Header';

const EditUtils = () => {
  const { token } = useAuth();
  const [slides, setSlides] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const [durationValues, setDurationValues] = useState({});
  const [activeValues, setActiveValues] = useState({});
  const [orderValues, setOrderValues] = useState({});
  const [editSlide, setEditSlide] = useState(null);
  const [newDuration, setNewDuration] = useState('');
  const [newActive, setNewActive] = useState('');
  const [newOrder, setNewOrder] = useState('');

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    const sortedSlides = [...slides].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setSortConfig({ key, direction });
    setSlides(sortedSlides);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key !== key) {
      return <FontAwesomeIcon icon={faSort} />;
    }
    if (sortConfig.direction === 'ascending') {
      return <FontAwesomeIcon icon={faSortUp} />;
    }
    return <FontAwesomeIcon icon={faSortDown} />;
  };

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const response = await axios.get('https://dash2.axc.ae/api/all-slides', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setSlides(response.data);
        const initialDurations = response.data.reduce((acc, slide) => {
          acc[slide.id] = slide.duration / 1000; // Save duration in seconds
          return acc;
        }, {});
        setDurationValues(initialDurations);
        const initialActives = response.data.reduce((acc, slide) => {
          acc[slide.id] = slide.active;
          return acc;
        }, {});
        setActiveValues(initialActives);
        const initialOrders = response.data.reduce((acc, slide) => {
          acc[slide.id] = slide.order_num;
          return acc;
        }, {});
        setOrderValues(initialOrders);
      } catch (error) {
        console.error('Ошибка при получении слайдов:', error);
      }
    };

    fetchSlides();
  }, [token]);

  const handleDurationChange = (e) => {
    setNewDuration(e.target.value);
  };

  const handleActiveChange = (e) => {
    setNewActive(e.target.value);
  };

  const handleOrderChange = (e) => {
    setNewOrder(e.target.value);
  };

  const handleSave = async (id) => {
    const updatedDuration = newDuration * 1000; // Convert to milliseconds
    const updatedActive = parseInt(newActive, 10);
    const updatedOrder = parseInt(newOrder, 10);
    try {
      await axios.post('https://dash2.axc.ae/api/admin/update-duration-status', {
        id,
        duration: updatedDuration,
        active: updatedActive,
        order_num: updatedOrder
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      // Обновление состояния после успешного запроса
      setSlides((prevSlides) =>
        prevSlides.map((slide) =>
          slide.id === id
            ? { ...slide, duration: updatedDuration, active: updatedActive, order_num: updatedOrder }
            : slide
        )
      );
      setDurationValues((prevDurationValues) => ({
        ...prevDurationValues,
        [id]: newDuration
      }));
      setActiveValues((prevActiveValues) => ({
        ...prevActiveValues,
        [id]: updatedActive
      }));
      setOrderValues((prevOrderValues) => ({
        ...prevOrderValues,
        [id]: updatedOrder
      }));

      setEditSlide(null);
      alert('Изменения сохранены');
    } catch (error) {
      console.error('Ошибка при сохранении изменений:', error);
      alert('Ошибка при сохранении изменений');
    }
  };

  const getActiveStatus = (status) => {
    switch (status) {
      case 1:
        return <p className='active'>Active</p>;
      case 0:
        return <p className='inactive'>Inactive</p>;
      default:
        return null;
    }
  };
  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <table className="table table-dark table-striped table-bordered mt-3">
              <thead>
                <tr>
                    <th>ID</th>
                    <th scope="col" onClick={() => handleSort('type')}>
                        Type {getSortIcon('type')}
                    </th>
                    <th scope="col" onClick={() => handleSort('order_num')}>
                        Order Num {getSortIcon('order_num')}
                    </th>
                    <th scope="col" onClick={() => handleSort('active')}>
                        Status {getSortIcon('active')}
                    </th>
                    <th scope="col">Duration (sec)</th>
                    <th scope="col">Data</th>
                    <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody className="table-group-divider">
                {slides.map((slide) => (
                  <tr key={slide.id}>
                    <td style={{ width: '5%' }}>{slide.id}</td>
                    <td style={{ width: '15%' }}>{slide.type}</td>
                    <td style={{ width: '10%' }}>
                      {editSlide === slide.id ? (
                        <input
                          type="number"
                          value={newOrder}
                          className="form-control input-edit-slide" 
                          onChange={handleOrderChange}
                        />
                      ) : (
                        <span>{orderValues[slide.id]}</span>
                      )}
                    </td>
                    <td style={{ width: '10%' }}>
                      {editSlide === slide.id ? (
                        <select className="form-select form-select-sm" value={newActive} onChange={handleActiveChange}>
                          <option value="1">Active</option>
                          <option value="0">Inactive</option>
                        </select>
                      ) : (
                        <span>{getActiveStatus(slide.active)}</span>
                      )}
                    </td>
                    <td style={{ width: '10%' }}>
                      {editSlide === slide.id ? (
                        <input
                          type="number"
                          className="form-control input-edit-slide" 
                          value={newDuration}
                          onChange={handleDurationChange}
                        />
                      ) : (
                        <span>{durationValues[slide.id]}</span>
                      )}
                    </td>
                    <td style={{ width: '30%' }}></td>
                    <td>
                      {editSlide === slide.id ? (
                        <button onClick={() => handleSave(slide.id)}>Save</button>
                      ) : (
                        <FontAwesomeIcon icon={faEdit} onClick={() => {
                          setEditSlide(slide.id);
                          setNewDuration(durationValues[slide.id]);
                          setNewActive(activeValues[slide.id]);
                          setNewOrder(orderValues[slide.id]);
                        }} />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUtils;
